import styled from "styled-components"

export const FooterWrapper = styled.div`

background:linear-gradient(to right, rgba(3, 7, 29, 0.95), rgba(9, 64, 110, 0.9));
display: flex;
flex-direction:column;
align-items: center;
justify-content: space-between;
position: relative;
padding-top:35px;
max-width: 100%;
color:white;

.footer-top {
  width: 90%;
  margin: 0 auto;
  margin-bottom: 35px;
  @media screen and (min-width: 768px) {
  width:60%;
  }

  p {
    a {
      color:white;
      font-weight:bold;
    }
  }
}
.footer-bottom {
  width: 100%;
  display: none;
  flex-direction:column;

  @media screen and (min-width: 768px) {
    flex-direction:row;

  }
}

div {
  text-align:center;

  h5 {
    color:#ccaf89;
    font-family: 'Nunito', sans-serif;
    font-size:.8rem;
    letter-spacing:3px;
    text-transform:uppercase;
  }
  p {
    font-size:.85rem;
  }
}

.links {
  display:flex;
  flex-direction:column; 

  p {
    margin-top: 0;
  }

  a {
    color: white;
    font-family:'Nunito', sans-serif;
    font-size:.85rem;
    height:50px;
    @media screen and (min-width: 768px) {
    height:20px;
    }
  }
}

@media screen and (min-width: 768px) {
    
  flex-direction:column;
  align-items:start;
  padding-top:35px;
  padding-bottom:35px;

  div {
    flex: 1 1 0;
    width: 0;
    
    
  }

  .links {
    a {
      transition:.3s;
        :hover {
          color:#ccaf89;
        }
      }
    }
  }
}
`
