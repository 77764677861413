import React from 'react'
import { Link } from 'gatsby'
// import Image from "gatsby-image"
//import { FaFacebookSquare, FaInstagram, FaMailBulk } from 'react-icons/fa';
import 'animate.css/animate.css'
import scrollTo from 'gatsby-plugin-smoothscroll';


// Styles
import { HeroSides, Logo } from "./HeroTwoSides.styles"

//Hooks 
import { useBilderDataQuery } from 'Hooks/useBilderQuery'
import { useSiteConfigQuery } from 'Hooks/useSiteConfigQuery'


const HeroTwoSides = () => {
    const siteConfig = useSiteConfigQuery()
    const { 
        herobg
        
    } = useBilderDataQuery()

    const hero = herobg.childImageSharp.fluid.src;

    return (
    <HeroSides style={{background:'linear-gradient(to right, rgba(3,7,29,.95),rgba(9,64,110,.90)),url('+ hero +')'}}>    
        <div className="left" >
        <Logo src={siteConfig.logo.publicURL} alt="Städfirmor Göteborg" />

        </div>
        <div className="right">
            <h1><strong>Städfirmor Göteborg?</strong> Vi hjälper
                dig att hitta rätt <strong>städfirma.</strong> </h1>
            <div className="knappar"> 
                <a onClick={() => scrollTo('#offert')}  className="animate__animated animate__fadeIn animate__delay-1s">
                    Offert
                </a>
                <Link to="/kontakt" className="animate__animated animate__fadeIn animate__delay-2s">
                    Kontakt
                </Link>     
            </div>
            <p className="nationell">
            Fyll i ett enkel offertförfrågan där du beskriver 
            din önskan och vi skackar det till samtliga 
            städfirmor i Göteborg. Det ska inte vara tidskrävande för att hitta eller byta till rätt städfirma </p>
        </div>
    </HeroSides>
)
}


export default HeroTwoSides 