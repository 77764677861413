import React  from 'react'
import { Link } from 'gatsby'
import { FaFacebookSquare, FaInstagram, FaMailBulk, FaStar } from 'react-icons/fa';
 
// Styles
import { FooterWrapper, FooterWrapper2, FooterBottom } from "./Footer.styles"

//Hooks

// {footerQuery.tjanst.map(item => (
//     <Link to={item.link} key={item.id}>
//         {item.name}
//     </Link>
// )) }

// import { useFooterMetaQuery } from 'Hooks/useFooterQuery'

const Footer = () => { 

    // const footerQuery = useFooterMetaQuery()

    return(
    <FooterWrapper> 
        <div className="footer-top" id="footer">
            <p>Om du vill ansluta ditt företag måste du ha yrkestrafiktillstånd 
            och jobbar aktivt inom städbranchen. 
            Du är välkommen att kontakta oss via vårt formulär på <Link to="/kontakt">kontakt</Link> sidan.
            </p>
        </div>
    </FooterWrapper>
    )
}
export default Footer

